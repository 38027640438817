<template>
  <div>
    <div v-if="userExporters.length > 1" class="suppliers__exporters">
      <ExportersSelect
        :id="currentExporterId"
        :options="userExporters"
        @change="changeExporter"
      />
    </div>
    <ExporterSuppliersMobile
      v-if="isMobile"
      :table-data="preparedTableData"
      :current-exporter-id="currentExporterId"
      @editRow="handleEdit"
      @deleteRow="handleDelete"
      @selectDropdownMenu="handleTableMenu"
      @selectionChange="handleSelectionChange"
    />

    <div
      v-else
      v-loading="isLoading"
      class="control-table control-table-suppliers"
    >
      <template v-if="currentExporterId">
        <div
          v-if="tableData.length || search"
          class="container container--full-screen"
        >
          <TableHeader
            :search="search"
            search-placeholder="Поиск по поставщикам для квоты"
            search-width="330px"
            @input="handleFilterInput"
            @clear="handleClearFilter"
            @search="handleSearchFilter"
          />
          <TableBody
            v-if="tableData.length"
            :table-data="preparedTableData"
            :table-fields-desktop="exporterSuppliersFieldsDesktop"
            :table-fields-tablet="exporterSuppliersFieldsDesktop"
            :table-fields-mobile="exporterSuppliersFieldsMobile"
            :table-fields-small-mobile="exporterSuppliersFieldsMobile"
            :table-dropdown-menu-list="exporterSuppliersDropdownMenuList"
            :is-loading="isLoading"
            @editRow="handleEdit"
            @deleteRow="handleDelete"
            @selectDropdownMenu="handleTableMenu"
            @selectionChange="handleSelectionChange"
          >
            <template #default="slotProps">
              <TableCell
                :row="slotProps.data.row"
                :item="slotProps.data.row[slotProps.data.prop]"
                :prop-key="slotProps.data.prop"
                :status="slotProps.data.row.status"
                :width="slotProps.data.width"
                :min-width="slotProps.data.minWidth"
              />
            </template>
          </TableBody>

          <div v-else class="no-filtered">
            В списке нет поставщиков, соответствующих указанному фильтру
          </div>

          <TableFooter
            :pagination="pagination"
            text-quantity="Всего поставщиков:"
            item-names="поставщиков"
            @page="changePage"
            @size="changeSize"
          />
        </div>
        <TableEmpty
          v-if="!tableData.length && !search && !isLoading"
          warning="У вас не создано ни одного поставщика"
          proposition="Добавить поставщика"
          @add="addNewSupplier"
        />
      </template>
    </div>

    <DialogEditSupplier :params="params" />
    <DialogRemoveTableRow :params="params" />
  </div>
</template>

<script>
import {
  CONTROL_TABLE_SUPPLIER_EDIT_ROW,
  CONTROL_TABLE_SUPPLIER_REMOVE_ROW,
} from '@/constants/dialogs'
import {
  FETCH_SUPPLIERS_PAGE,
  GET_IS_LOADING_SUPPLIERS,
  GET_SUPPLIERS_PAGE,
  GET_SUPPLIERS_PAGINATION,
  GET_USER_EXPORTERS,
} from '../store/actions'
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID } from '@/store/actions'
import { TableBody, TableEmpty, TableFooter, TableHeader } from '@/UI/table'
import {
  controlTableAddItem,
  controlTableRemoveSelectedItems,
} from '@/views/control/data/control'
import {
  exporterSuppliersDropdownMenuList,
  exporterSuppliersFieldsDesktop,
  exporterSuppliersFieldsMobile,
} from './data/ExporterSuppliers'
import { mapActions, mapGetters } from 'vuex'
import { phoneRegex } from '@/core/helpers'
import DialogEditSupplier from './components/dialogs/dialog-edit-supplier/DialogEditSupplier'
import DialogRemoveTableRow from './components/dialogs/dialog-remove-table-row/DialogRemoveTableRow'
import ExporterSuppliersMobile from './ExporterSuppliersMobile'
import ExportersSelect from './components/ExportersSelect.vue'
import TableCell from './components/TableCell'

export default {
  name: 'ExporterSuppliers',
  components: {
    DialogEditSupplier,
    DialogRemoveTableRow,
    TableFooter,
    TableBody,
    TableEmpty,
    TableHeader,
    TableCell,
    ExportersSelect,
    ExporterSuppliersMobile,
  },
  data() {
    return {
      exporterSuppliersFieldsDesktop,
      exporterSuppliersFieldsMobile,
      exporterSuppliersDropdownMenuList,
      multipleSelectionIds: [],
      multipleSelectionNames: [],
      page: 1,
      perPage: 10,
      search: '',
      currentExporterId: '',
    }
  },
  computed: {
    ...mapGetters({
      tableData: GET_SUPPLIERS_PAGE,
      pagination: GET_SUPPLIERS_PAGINATION,
      isLoading: GET_IS_LOADING_SUPPLIERS,
      isMobile: GET_IS_MOBILE,
      userExporters: GET_USER_EXPORTERS,
      currentTerminal: GET_TERMINAL_CURRENT_ID,
    }),
    // предварительно преобразуем телефоны экспортеров из формата БД в читаемый
    preparedTableData() {
      return this.tableData.map(item => {
        let newExporter = {}

        for (let key in item) {
          if (key !== 'phones') {
            newExporter[key] = item[key]
          } else {
            newExporter[key] = item[key].map(user => {
              let formattedPhone = {}

              for (let field in user) {
                formattedPhone[field] =
                  field === 'phone' ? phoneRegex(user[field]) : user[field]
              }

              return formattedPhone
            })
          }
        }

        return newExporter
      })
    },
    params() {
      return {
        search: this.search,
        page: this.page,
        'per-page': this.perPage,
        exporter_id: Number(this.currentExporterId),
      }
    },
  },
  watch: {
    currentTerminal() {
      this.currentExporterId = ''
    },
    userExporters() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({ supplierFetch: FETCH_SUPPLIERS_PAGE }),
    init() {
      if (this.userExporters.length === 1) {
        this.currentExporterId = String(this.userExporters[0].id)
        this.fetchSuppliers()
      }
    },
    fetchSuppliers() {
      this.supplierFetch(this.params)
    },
    handleFilterInput(value) {
      this.search = value
    },
    handleClearFilter() {
      this.search = ''
      this.handleSearchFilter()
    },
    handleSearchFilter() {
      this.fetchSuppliers()
    },
    changeExporter(id) {
      if (id !== this.currentExporterId) {
        this.currentExporterId = id
        this.fetchSuppliers()
      }
    },
    changePage(page = 1) {
      this.page = page
      this.fetchSuppliers()
    },
    changeSize(size = 10) {
      this.perPage = size
      this.fetchSuppliers()
    },
    handleEdit(row) {
      this.setDialog({
        name: CONTROL_TABLE_SUPPLIER_EDIT_ROW,
        visible: true,
        data: row,
      })
    },
    handleDelete({ id, name }) {
      this.setDialog({
        name: CONTROL_TABLE_SUPPLIER_REMOVE_ROW,
        visible: true,
        data: {
          isMultipleDelete: false,
          id,
          name,
        },
      })
    },
    handleTableMenu(command) {
      if (command === controlTableRemoveSelectedItems) {
        this.setDialog({
          name: CONTROL_TABLE_SUPPLIER_REMOVE_ROW,
          visible: true,
          data: {
            isMultipleDelete: true,
            idsList: this.multipleSelectionIds,
            namesList: this.multipleSelectionNames,
          },
        })
      }
      if (command === controlTableAddItem) {
        this.addNewSupplier()
      }
    },
    handleSelectionChange({ ids, local_names }) {
      this.multipleSelectionIds = ids
      this.multipleSelectionNames = local_names
    },
    addNewSupplier() {
      this.setDialog({
        name: CONTROL_TABLE_SUPPLIER_EDIT_ROW,
        data: { exporterId: Number(this.currentExporterId) },
        visible: true,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.control-table-suppliers
  padding-top: 20px
  margin-top: 20px
.suppliers__exporters
  margin: 20px 0
  padding: 0 40px
.no-filtered
  display: flex
  justify-content: center
  align-items: center
  font-size: 24px
  height: 200px
  color: $main-font
</style>
