var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.userExporters.length > 1
        ? _c(
            "div",
            { staticClass: "suppliers__exporters" },
            [
              _c("ExportersSelect", {
                attrs: {
                  id: _vm.currentExporterId,
                  options: _vm.userExporters,
                },
                on: { change: _vm.changeExporter },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isMobile
        ? _c("ExporterSuppliersMobile", {
            attrs: {
              "table-data": _vm.preparedTableData,
              "current-exporter-id": _vm.currentExporterId,
            },
            on: {
              editRow: _vm.handleEdit,
              deleteRow: _vm.handleDelete,
              selectDropdownMenu: _vm.handleTableMenu,
              selectionChange: _vm.handleSelectionChange,
            },
          })
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "control-table control-table-suppliers",
            },
            [
              _vm.currentExporterId
                ? [
                    _vm.tableData.length || _vm.search
                      ? _c(
                          "div",
                          { staticClass: "container container--full-screen" },
                          [
                            _c("TableHeader", {
                              attrs: {
                                search: _vm.search,
                                "search-placeholder":
                                  "Поиск по поставщикам для квоты",
                                "search-width": "330px",
                              },
                              on: {
                                input: _vm.handleFilterInput,
                                clear: _vm.handleClearFilter,
                                search: _vm.handleSearchFilter,
                              },
                            }),
                            _vm.tableData.length
                              ? _c("TableBody", {
                                  attrs: {
                                    "table-data": _vm.preparedTableData,
                                    "table-fields-desktop":
                                      _vm.exporterSuppliersFieldsDesktop,
                                    "table-fields-tablet":
                                      _vm.exporterSuppliersFieldsDesktop,
                                    "table-fields-mobile":
                                      _vm.exporterSuppliersFieldsMobile,
                                    "table-fields-small-mobile":
                                      _vm.exporterSuppliersFieldsMobile,
                                    "table-dropdown-menu-list":
                                      _vm.exporterSuppliersDropdownMenuList,
                                    "is-loading": _vm.isLoading,
                                  },
                                  on: {
                                    editRow: _vm.handleEdit,
                                    deleteRow: _vm.handleDelete,
                                    selectDropdownMenu: _vm.handleTableMenu,
                                    selectionChange: _vm.handleSelectionChange,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (slotProps) {
                                          return [
                                            _c("TableCell", {
                                              attrs: {
                                                row: slotProps.data.row,
                                                item: slotProps.data.row[
                                                  slotProps.data.prop
                                                ],
                                                "prop-key": slotProps.data.prop,
                                                status:
                                                  slotProps.data.row.status,
                                                width: slotProps.data.width,
                                                "min-width":
                                                  slotProps.data.minWidth,
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1404414410
                                  ),
                                })
                              : _c("div", { staticClass: "no-filtered" }, [
                                  _vm._v(
                                    " В списке нет поставщиков, соответствующих указанному фильтру "
                                  ),
                                ]),
                            _c("TableFooter", {
                              attrs: {
                                pagination: _vm.pagination,
                                "text-quantity": "Всего поставщиков:",
                                "item-names": "поставщиков",
                              },
                              on: {
                                page: _vm.changePage,
                                size: _vm.changeSize,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.tableData.length && !_vm.search && !_vm.isLoading
                      ? _c("TableEmpty", {
                          attrs: {
                            warning: "У вас не создано ни одного поставщика",
                            proposition: "Добавить поставщика",
                          },
                          on: { add: _vm.addNewSupplier },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
      _c("DialogEditSupplier", { attrs: { params: _vm.params } }),
      _c("DialogRemoveTableRow", { attrs: { params: _vm.params } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }