import {
  controlTableAddItem,
  controlTableRemoveSelectedItems,
} from '@/views/control/data/control'

export const exporterSuppliersFieldsDesktop = [
  { prop: 'name', label: 'Поставщик для квоты' },
  { prop: 'inn', label: 'ИНН', width: '150px', align: 'center' },
  { prop: 'fio', label: 'ФИО', width: '300px', align: 'center' },
  { prop: 'phones', label: 'Телефоны', width: '200px', align: 'center' },
]

export const exporterSuppliersFieldsMobile = [
  { prop: 'allOption', label: ['Наименование', 'Телефоны'], align: 'center' },
]

export const exporterSuppliersDropdownMenuList = [
  {
    id: 1,
    label: 'Удалить выбранные',
    value: controlTableRemoveSelectedItems,
    class: 'm-b-12',
  },
  {
    id: 2,
    label: 'Добавить поставщика',
    value: controlTableAddItem,
    isDivided: true,
  },
]
